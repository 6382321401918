@font-face {
    font-family: 'Nantes';
    src: url(fonts/Nantes-Regular.otf) format('opentype');
}

@font-face {
    font-family: "Gilroy";
    src: url(fonts/Gilroy-Regular.otf) format("opentype");
}

body {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(27, 31, 59, 0.8);
}

html, body, #root {
    height: 100%;
    font-family: 'Gilroy', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html #root h1, html #root h2, html #root h3, html #root h4, html #root h5, html #root h6 {
    font-family: 'Nantes', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
}


